import consumer from "./consumer"

consumer.subscriptions.create("PdfChannel", {

  connected() {
    // console.log("connected to route channel")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data)
    
    if (data["is_error"] == true) {
      toastr.error(data["message"]);
    } else {
      if (data["is_done"] == true) {
        toastr.success(data["message"]);
        // debugger;
        if (data["unit_id"]) {
          // used in units form after stripping photos
          $("#unit_prevent_reload").val("0")
          var id = "#edit_unit_" + data["unit_id"];
          var button = $(id).find(":submit")[0]
          $(button).click();

        } else {
          location.reload();
        }

      } else if (data["path"].includes("units")) {
        toastr.success(data["message"]);
        // units form - resubmit Units form to show images
        
        // resubmit form
        var id = "#edit_unit_" + data["unit_id"];
        var button = $(id).find(":submit")[0]
        $(button).click();
        
      } else if (location.pathname == data["path"] && data["is_done"] == true) {
        // Thinking this is on the locations photos page.
        toastr.success(data["message"]);
        location.reload();
      } else {
        // Not sure what the use case is here, but let's leave it
        toastr.success(data["message"], "", {timeOut: 3000, extendedTimeOut: 0})
      }
    }
  }
});